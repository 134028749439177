

import store from '@/store';
import { Options, Vue } from 'vue-class-component';


@Options({
    components: {
    }
})
export default class DeleteAccount extends Vue {


    created() {
    }
}
